@import 'mixins/helper-color';
@import 'mixins/helper-font-size';
@import 'mixins/helper-font-weight';
@import 'mixins/helper-size';
@import 'mixins/helper-spacing';

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}
