@import "functions";
@import "variables";
@import "color-palette";
@import "mixins";

/* Helper Font */
@include helper-font-size(1, 80);
@include helper-font-weight(1, 8);

/* Helper Margin */
@include helper-spacing("", "margin", "m");
@include helper-spacing("top", "margin", "mt");
@include helper-spacing("right", "margin", "me");
@include helper-spacing("bottom", "margin", "mb");
@include helper-spacing("left", "margin", "ms");
@include helper-spacing("mx", "margin", "mx");
@include helper-spacing("my", "margin", "my");

/* Helper Padding */
@include helper-spacing("", "padding", "p");
@include helper-spacing("top", "padding", "pt");
@include helper-spacing("right", "padding", "pe");
@include helper-spacing("bottom", "padding", "pb");
@include helper-spacing("left", "padding", "ps");
@include helper-spacing("px", "padding", "px");
@include helper-spacing("py", "padding", "py");

@each $color, $value in $theme-colors {
  @include helper-color($color, $value);
}

.bg-gray {
  background-color: $gray;
}

body {
  min-width: 768px;
  @include theme-aware("background", "body-background");
}

.header {
  @include theme-aware("background", "header-background");
  @include theme-aware("color", "header-color");

  .title {
    line-height: 50px;
  }
}

.custom-header {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;

  @include theme-aware("background", "header-background");
  @include theme-aware("color", "header-color");

  .logo {
    padding: 0.5rem;

    img {
      max-height: 35px;
    }
  }

  .title {
    line-height: 50px;
  }

  .user-menu {
    float: right;
    height: 100%;
    border-radius: 0px;
    background-color: $red;
    border-color: $red;
    color: $white;
  }

  .ant-btn-default.user-menu:not(:disabled):hover {
    background-color: $white !important;
    border-color: $red !important;
    color: $black !important;
  }
}

.user-drop {
  .ant-dropdown-menu {
    background-color: $white !important;
    border-color: $red !important;
    color: $black !important;
  }
}

.container {
  background-color: $white !important;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
}

.search-bar {
  .ant-btn-default.bg-gray:not(:disabled):hover {
    border-color: $black !important;
    color: $black !important;
  }

  .ant-btn-default.bg-gray.selected {
    border-color: $red !important;
    background-color: $red !important;
    color: $white !important;
  }
  .ant-btn-default.bg-gray.selected:hover {
    border-color: $red-600 !important;
    background-color: $red-600 !important;
    color: $white !important;
  }
}

.ant-table {
  overflow: auto;
}

.custom-table {
  .ant-table-cell {
    font-size: 12px;
  }
  .ant-table-thead > tr > th.ant-table-cell,
  .ant-table-thead > tr > th {
    background-color: $white;
    border-bottom-color: $gray-600;
    color: $table-th;
  }
  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-tbody .ant-table-row:nth-child(odd) {
    background-color: $gray;
  }
  .ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: $white;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: $gray-600;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0.2rem 0.2rem;
  }
  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-word;
  }
}

.custom-pager {
  text-align: center;

  .ant-pagination-item-active {
    background-color: $red;
    border-color: $red;
    border-radius: 50%;

    a {
      color: $white;
    }
  }

  .ant-pagination-item-active:hover {
    background-color: $red-600;
    border-color: $red-600;
    border-radius: 50%;

    a {
      color: $white;
    }
  }
}

.card-wrapper {
  @include theme-aware("margin", "card-wrapper-margin");
  @include theme-aware("padding", "card-wrapper-padding");
  @include theme-aware("border-radius", "card-wrapper-border-radius");
  @include theme-aware("background-color", "card-wrapper-background-color");
  @include theme-aware("font-size", "card-wrapper-font-size");
}

.email-body-header {
  .ant-collapse-header-text {
    @include theme-aware("font-size", "email-body-header-font-size");
    @include theme-aware("font-weight", "email-body-header-font-weight");
  }
}

.invoice-fields {
  margin-top: 1rem;

  .ant-form-item {
    margin-bottom: 5px;
  }

  .field-container {
    @include theme-aware("margin-top", "invoice-fields-field-container-margin-top");
    flex: 1;
    flex-direction: column;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @include theme-aware("font-size", "invoice-fields-field-container-font-size");

    .field-label {
      flex: 1;
      padding-top: 10px;
    }
    .field-value {
      flex: 2;
      overflow: auto;
    }
    .field-value > *:disabled {
      color: $gray-800;
    }
    .text-area {
      margin: 4px 0 4px 0;
    }
  }
}

.text-end {
  text-align: right;
}

.text-start {
  text-align: left;
}
