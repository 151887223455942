@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary: #393b40;
  --white: #ffffff;
  --lightGray: #ecf0f1;
  --amplify-primary-color: #393b40;
  --amplify-primary-tint: #c2c7cf;
  --amplify-primary-shade: #393b40;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ant-menu-item-selected,
.ant-select-item-option-active,
.ant-select-item-option-selected {
  background-color: #f4f5f7 !important;
}

.ant-table-row {
  cursor: pointer;
}

.submitted {
  background-color: #55efc4;
}

.needs_review {
  background-color: #55efc4;
}
.failed {
  background-color: #fab1a0;
}
.new {
  background-color: white;
}

@primary-color: #393B40;@border-radius-base: 5px;