// 1.0 GLOBAL - Color system
$black:       #000000 !default;
$blue:        #348fe2 !default;
$cyan:        #49b6d6 !default;
$dark:        #2d353c !default;
$dark-darker: #1a2229 !default;
$green:       #32a932 !default;
$gray:        #F5F5F5 !default;
$indigo:      #8753de !default;
$lime:        #90ca4b !default;
$light:       #f2f3f4 !default;
$orange:      #f59c1a !default;
$purple:      #727cb6 !default;
$pink:        #fb5597 !default;
$red:         #C73B38 !default;
$silver:      #b6c2c9 !default;
$teal:        #00acac !default;
$white:       #FFFFFF !default;
$yellow:      #ffd900 !default;

$gray-100:   tint-color($gray, 80%) !default;
$gray-200:   tint-color($gray, 60%) !default;
$gray-300:   tint-color($gray, 40%) !default;
$gray-400:   tint-color($gray, 20%) !default;
$gray-500:   $gray !default;
$gray-600:   shade-color($gray, 20%) !default;
$gray-700:   shade-color($gray, 40%) !default;
$gray-800:   shade-color($gray, 60%) !default;
$gray-900:   shade-color($gray, 80%) !default;

$red-100:   tint-color($red, 80%) !default;
$red-200:   tint-color($red, 60%) !default;
$red-300:   tint-color($red, 40%) !default;
$red-400:   tint-color($red, 20%) !default;
$red-500:   $red !default;
$red-600:   shade-color($red, 20%) !default;
$red-700:   shade-color($red, 40%) !default;
$red-800:   shade-color($red, 60%) !default;
$red-900:   shade-color($red, 80%) !default;

$default:     $gray-200 !default;
$primary:     $blue !default;
$secondary:   $gray-600 !default;
$success:     $green !default;
$info:        $cyan !default;
$warning:     $orange !default;
$danger:      $red !default;
$theme:       $blue !default;
$theme-color: $white !default;

$table-th: #8E8E8E;

$theme-colors: (
	"default":    $default,
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "lime":       $lime,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-500,
  "gray-dark":  $gray-800,
  "muted":      $gray-500,
	"black":      $black,
	"silver":     $silver,
	"gray-100":   $gray-100,
	"gray-200":   $gray-200,
	"gray-300":   $gray-300,
	"gray-400":   $gray-400,
	"gray-500":   $gray-500,
	"gray-600":   $gray-600,
	"gray-700":   $gray-700,
	"gray-800":   $gray-800,
	"gray-900":   $gray-900,
);

// 2.0 GLOBAL - Grid & Container
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
  xxxl: 1800px
);
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1170px
) !default;

